import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";
import { getSrcSet } from "gatsby-plugin-image";
import Layout from "../components/common/layout";
import Seo from "../components/common/seo";
import Content from "../components/posts/content";
import Pagination from "../components/posts/pagination";
import ImageHeader from "../components/posts/imageHeader";
import Lightbox from "../components/posts/lightbox";
import { GetMetaImageLink } from "../utils/helpers";

export default function PostPageTemplate({
  data,
  pageContext,
  location,
}: PageProps<Queries.PostQuery>) {
  const { current, totalCount }: any = pageContext;
  const [lightOpen, setLightOpen] = useState(false);
  const image = data.post?.frontmatter?.image?.childImageSharp?.gatsbyImageData;
  const imageSrcSet = image
    ? getSrcSet(data.post?.frontmatter?.image?.childImageSharp?.gatsbyImageData)
    : undefined;

  return (
    <>
      <Layout>
        <Seo
          title={`${data.post?.frontmatter?.title} - ${data.site?.siteMetadata?.title}`}
          pathName={location.pathname}
          desc={`${data.post?.excerpt}`}
          date={`${data.post?.frontmatter?.pubDate}`}
          image={GetMetaImageLink(imageSrcSet)}
          origin={location.origin}
          isPost
        />
        <article>
          <ImageHeader
            post={data.post}
            setLightOpen={setLightOpen}
            lightOpen={lightOpen}
          />
          <Content post={data.post} />
          <Pagination
            previous={data.previous}
            next={data.next}
            current={current}
            totalCount={totalCount}
          />
        </article>
      </Layout>
      <Lightbox
        post={data.post}
        openHandler={setLightOpen}
        isOpen={lightOpen}
      />
    </>
  );
}

export const postQuery = graphql`
  query Post($id: String!, $previousPostId: String, $nextPostId: String) {
    post: markdownRemark(id: { eq: $id }) {
      id
      excerpt(format: PLAIN, pruneLength: 145)
      html
      frontmatter {
        title
        altText
        pubDate
        tags
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        metadata {
          camera
          lens
          focal
          aperture
          shutter
          iso
          location
          dimensions {
            width
            height
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
