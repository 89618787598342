import React from "react";
import { Link } from "gatsby";
import LeftArrow from "../../icons/arrow-left.svg";
import RightArrow from "../../icons/arrow-right.svg";

const Pagination = ({ previous, next, current, totalCount }: Props) => (
  <div className="flex justify-between w-full mb-8">
    <div className="block w-1/4 text-center">
      {previous && (
        <Link
          to={`${previous?.fields?.slug}`}
          className="opacity-30 hover:opacity-100"
        >
          <span className="sr-only">Go to previous post</span>
          <LeftArrow />
        </Link>
      )}
    </div>
    <div className="block w-1/4 text-center">
      {current}/{totalCount}
    </div>
    <div className="block w-1/4 text-center">
      {next && (
        <Link
          to={`${next?.fields?.slug}`}
          className="opacity-30 hover:opacity-100"
        >
          <span className="sr-only">Go to next post</span>
          <RightArrow />
        </Link>
      )}
    </div>
  </div>
);

export default Pagination;

type Props = {
  previous: { readonly fields: { readonly slug: string | null } | null } | null;
  next: { readonly fields: { readonly slug: string | null } | null } | null;
  current: number;
  totalCount: number;
};
