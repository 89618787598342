import React from "react";

const Metadata = ({ title, info }: Props) => {
  return (
    <p className="text-sm font-mono">
      <span>{title}:</span>
      &nbsp;{info}
    </p>
  );
};

export default Metadata;

type Props = {
  title: string;
  info: string;
};
