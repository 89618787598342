import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import Close from "../../icons/close-mini.svg";
import { PostContent } from "../../utils/types/postContent";
import { GatsbyImage } from "gatsby-plugin-image";

const Lightbox = ({ post, openHandler, isOpen }: Props) => (
  <Transition show={isOpen} as={Fragment}>
    <Dialog onClose={() => openHandler(false)} className="relative z-50">
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/95" aria-hidden="true" />
      </Transition.Child>

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {/* The actual dialog panel  */}
          <Dialog.Panel>
            <div className="h-screen w-screen justify-center items-center flex flex-col">
              <GatsbyImage
                //@ts-ignore
                image={
                  post?.frontmatter?.image?.childImageSharp?.gatsbyImageData
                }
                className="max-w-full min-h-screen w-auto h-auto"
                imgStyle={{ objectFit: "contain" }}
                alt=""
              />
              {/* "top-10" to fix mobile Safari issue  */}
              <button
                className="absolute top-10 font-bold text-black text-sm bg-white border shadow px-2 py-1 flex items-center"
                onClick={() => openHandler(false)}
              >
                Close
                <span className="pl-2">
                  <Close />
                </span>
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
);

export default Lightbox;

type Props = {
  post: PostContent;
  openHandler: (isOpen: boolean) => void;
  isOpen: boolean;
};
