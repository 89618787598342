import React from "react";
import { Link } from "gatsby";
import Metadata from "./metadata";
import Tags from "../../icons/tags.svg";
import Location from "../../icons/location.svg";
import Camera from "../../icons/camera.svg";
import Dimensions from "../../icons/dimensions.svg";
import { GetTagLink } from "../../utils/helpers";
import { PostContent } from "../../utils/types/postContent";

const Content = ({ post }: Props) => (
  <div className="my-8 max-w-[65ch] md:max-w-full mx-auto">
    <div className="flex flex-wrap justify-between w-full">
      <div
        className="w-full md:w-3/5 prose dark:prose-invert dark:text-white"
        dangerouslySetInnerHTML={{ __html: `${post?.html}` }}
      />
      <div className="mt-5 md:-mt-5 w-full md:w-1/4 prose dark:prose-invert dark:text-white prose-p:my-4">
        {post?.frontmatter?.metadata?.location && (
          <p>
            <span className="pr-2">
              <Location />
            </span>
            <span className="sr-only">Location: </span>
            {post?.frontmatter?.metadata?.location}
          </p>
        )}
        {post?.frontmatter?.metadata?.camera && (
          <p>
            <span className="pr-2">
              <Camera />
            </span>
            <span className="sr-only">Camera: </span>
            {post?.frontmatter?.metadata?.camera}
          </p>
        )}
        {post?.frontmatter?.metadata?.dimensions?.width &&
          post?.frontmatter?.metadata?.dimensions?.height && (
            <p>
              <span className="pr-2">
                <Dimensions />
              </span>
              <span className="sr-only">Original dimensions: </span>
              {post?.frontmatter?.metadata?.dimensions?.width}
              &nbsp;x&nbsp;
              {post?.frontmatter?.metadata?.dimensions?.height}
            </p>
          )}
        {post?.frontmatter?.tags && (
          <p>
            <span className="pr-2">
              <Tags />
              <span className="sr-only">Tagged: </span>
            </span>
            {post?.frontmatter?.tags?.map((tag, i) => (
              <span key={i}>
                <Link to={GetTagLink(`${tag}`, false)}>{tag}</Link>
                {i + 1 !== post?.frontmatter?.tags?.length ? (
                  <>&nbsp;&nbsp;</>
                ) : (
                  ""
                )}
              </span>
            ))}
          </p>
        )}
        <p>---</p>
        <Metadata title="lens" info={`${post?.frontmatter?.metadata?.lens}`} />
        <Metadata
          title="focal"
          info={`${post?.frontmatter?.metadata?.focal}`}
        />
        <Metadata
          title="aperture"
          info={`${post?.frontmatter?.metadata?.aperture}`}
        />
        <Metadata
          title="shutter"
          info={`${post?.frontmatter?.metadata?.shutter}`}
        />
        <Metadata
          title="iso"
          info={`${post?.frontmatter?.metadata?.iso?.toString()}`}
        />
      </div>
    </div>
  </div>
);

export default Content;

type Props = {
  post: PostContent;
};
