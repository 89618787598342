import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Fullscreen from "../../icons/fullscreen.svg";
import { PostContent } from "../../utils/types/postContent";

const ImageHeader = ({ post, setLightOpen, lightOpen }: Props) => (
  <>
    <header className="mb-4">
      <h1 className="text-3xl font-bold mb-4 text-center">
        {post?.frontmatter?.title}
      </h1>
    </header>
    <GatsbyImage
      //@ts-ignore
      image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
      alt={`${post?.frontmatter?.altText}`}
      className="-mx-4 md:mx-0 min-h-fit"
    />
    <div className="bg-black text-white absolute -mx-4 md:mx-0 -mt-9 flex items-center">
      <button
        className="py-1 px-2 text-lg hover:animate-pulse"
        onClick={() => setLightOpen(!lightOpen)}
      >
        <Fullscreen />
        <span className="sr-only">Show in fullscreen</span>
      </button>
    </div>
  </>
);

export default ImageHeader;

type Props = {
  post: PostContent;
  setLightOpen: (isOpen: boolean) => void;
  lightOpen: boolean;
};
